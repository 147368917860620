import React, { useEffect } from 'react'; // Import useEffect to handle the component lifecycle
import { MdKeyboardDoubleArrowDown } from 'react-icons/md';
import $ from 'jquery';

const Scroll = () => {
  useEffect(() => {
    // Use the useEffect hook to handle the component lifecycle and avoid infinite loops
//     $(window).scroll(() => {
//       const scroll_down = $(this).scrollTop();
//       if (scroll_down > 100) {
//         $('#scroll_down').show(300);
//       } else {
//         $('#scroll_down').hide(300);
//       }
   
//     });
  }, []); // Add an empty dependency array to run this effect only once

  const scroll = () => {
    $(window).scrollTop(0);
  };

  return (
    <div>
      <div className="wrap_icon" id="scroll_down" onClick={scroll}>
        <div className="icon">
          <MdKeyboardDoubleArrowDown />
        </div>
      </div>
    </div>
  );
};

export default Scroll;
