import React from 'react'
import { useState, useEffect } from 'react';
import "../css/blod_detail.css";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
let slider1 = null;
let slider2 = null;
const BlogDetail = () => {
     const { id } = useParams();
     console.log(id)
     const [nav1, setNav1] = useState(null);
     const [nav2, setNav2] = useState(null);
        useEffect(() => {
          setNav1(slider1);
          setNav2(slider2);
        }, []);

        const [blogData, setBlogData] = useState([]);
       
        
        useEffect(() => {
          fetch(`https://apidata.foodbaskit.com/latest_blogs`)
            .then((res) => res.json())
            .then((result) => {
              let filterCat = id;
              let cat = result.filter((item) => item.id ==filterCat);
              
              setBlogData(cat);
            
            })
            .catch((error) => console.error(error));
        }, [id]);
        
  return (
    <div>
      <div className="container">

<div className="blog_details">
<div className="heading_main mt-2 mb-5">
     <h1>
     Blog details    {
      blogData.map((item)=>{
       return(
        <>
        {
          item.name
        }

    
        </>
       )
      })
     }
     
     </h1>
</div>
{
     blogData.map((item)=>{
          return(<>
               <div className="row" key={item.id}>
          <div className="col-md-4 mb-5">
               <div className="main_headings_blogs mb-2">
           <div className="heading_blog">
              <p className='key'>
               name
              </p>
              <div className="value">
              {item.name}
              </div>
              </div>

              <div className="heading_blog">
              <p className='key'>
               category
              </p>
              <div className="value">
              {item.category}
              </div>
              </div>

              <div className="heading_blog">
              <p className='key'>
               review's
              </p>
              <div className="value">
  {item.review >= 1 ? (
    <em className="star">
      <i className="fa-solid fa-star"></i>
    </em>
  ) : (
    <em className="star">
      <i className="far fa-star"></i>
    </em>
  )}
  {item.review >= 2 ? (
    <em className="star">
      <i className="fa-solid fa-star"></i>
    </em>
  ) : (
    <em className="star">
      <i className="far fa-star"></i>
    </em>
  )}
  {item.review >= 3 ? (
    <em className="star">
      <i className="fa-solid fa-star"></i>
    </em>
  ) : (
    <em className="star">
      <i className="far fa-star"></i>
    </em>
  )}
  {item.review >= 4 ? (
    <em className="star">
      <i className="fa-solid fa-star"></i>
    </em>
  ) : (
    <em className="star">
      <i className="far fa-star"></i>
    </em>
  )}
  {item.review >= 5 ? (
    <em className="star">
      <i className="fa-solid fa-star"></i>
    </em>
  ) : (
    <em className="star">
      <i className="far fa-star"></i>
    </em>
  )}
  {item.review}/5
</div>

              </div>
               </div>
          </div>

          <div className="col-md-8">
          <div className="blog_slider">
  <div className="top_slide">
    <Slider asNavFor={nav2} ref={(slider) => (slider1 = slider)}>
      <div>
        {item.image.endsWith(".mp4") ? (
          <video controls width="100%" height="300px">
            <source
              src={`https://apidata.foodbaskit.com/${item.image}`}
              type="video/mp4"
            />
          </video>
        ) : (
          <img
            src={`https://apidata.foodbaskit.com/${item.image}`}
            alt=""
            className="img-fluid  imge_main rounded"
          />
        )}
      </div>
      <div>
        {item.image2.endsWith(".mp4") ? (
          <video controls width="100%" height="300px">
            <source
              src={`https://apidata.foodbaskit.com/${item.image2}`}
              type="video/mp4"
            />
          </video>
        ) : (
          <img
            src={`https://apidata.foodbaskit.com/${item.image2}`}
            alt=""
            className="img-fluid  imge_main rounded"
          />
        )}
      </div>
      <div>
        {item.image3.endsWith(".mp4") ? (
          <video controls width="100%" height="300px">
            <source
              src={`https://apidata.foodbaskit.com/${item.image3}`}
              type="video/mp4"
            />
          </video>
        ) : (
          <img
            src={`https://apidata.foodbaskit.com/${item.image3}`}
            alt=""
            className="img-fluid  imge_main rounded"
          />
        )}
      </div>
      <div>
        {item.image4.endsWith(".mp4") ? (
          <video controls width="100%" height="300px">
            <source
              src={`https://apidata.foodbaskit.com/${item.image4}`}
              type="video/mp4"
            />
          </video>
        ) : (
          <img
            src={`https://apidata.foodbaskit.com/${item.image4}`}
            alt=""
            className="img-fluid  imge_main rounded"
          />
        )}
      </div>
    </Slider>
  </div>

  <div className="bottom_slider">
    <Slider
      asNavFor={nav1}
      ref={(slider) => (slider2 = slider)}
      slidesToShow={3}
      swipeToSlide={true}
      focusOnSelect={true}
    >
      <div className="">
        {item.image.endsWith(".mp4") ? (
          <> </>
        ) : (
          <img
            src={`https://apidata.foodbaskit.com/${item.image}`}
            alt=""
            className="img-fluid  imge_main rounded"
          />
        )}
      </div>
      <div className="" >
        {item.image2.endsWith(".mp4") ? (
          <> </>
        ) : (
          <img
            src={`https://apidata.foodbaskit.com/${item.image2}`}
            alt=""
            className="img-fluid  imge_main rounded"
          />
        )}
      </div>
      <div className="">
        {item.image3.endsWith(".mp4") ? (
          <> </>
    ) : (
      <img
        src={`https://apidata.foodbaskit.com/${item.image3}`}
        alt=""
        className="img-fluid  imge_main rounded"
      />
    )}
  </div>
  <div className="">
    {item.image4.endsWith(".mp4") ? (
     <> </>
    ) : (
      <img
        src={`https://apidata.foodbaskit.com/${item.image4}`}
        alt=""
        className="img-fluid  imge_main rounded"
      />
    )}
  </div>
</Slider>
  </div>
</div>

          </div>

          <div className="col-md-12">

               <div className="blog_others_details">
<div className="head">
     <h1>
          Others details
     </h1>
</div>


<div className="row">
     <div className="col-md-6">
          <div className="blog_wrapper_details">
<div className="heading">
<div className="icon">
          <i class="fa-solid fa-location-dot"></i>
          </div>
     <p>
       location 
     </p>
</div>
<div className="texts">
    {item.location}
</div>
          </div>
     </div>

     <div className="col-md-6">
          <div className="blog_wrapper_details">
<div className="heading">
<div className="icon">
     <i class="fa-solid fa-circle-info"></i>
          </div> 
     <p>
    Description 
     </p>
</div>
<div className="texts">
{item.description}

</div>
          </div>
     </div>




    
</div>
               </div>
          </div>
     </div>
          </>)
     })
}
    
</div>
 
          {/* the end contauiner */}
      </div>
    </div>
  )
}

export default BlogDetail
