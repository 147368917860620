import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import $ from "jquery";

const Blog = () => {
  const [blogData, setBlogData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const res = await fetch("https://apidata.foodbaskit.com/latest_blogs");
        const result = await res.json();
        let filterCat = "popular";
        let cat = result.filter((item) => item.type === filterCat);
        setBlogData(result);
        setCurrentPage(1); // Set the current page to 1 after setting the blogData state
 
      } catch (error) {
        console.error(error);
      }
    };
    
    fetchBlogs();
  }, []);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = blogData.filter((item) => item.name.toLowerCase().includes(searchTerm.toLowerCase())).slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(blogData.length / itemsPerPage);
  const pageNumbers = [];

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const addclass = () => {
    $(".menu_filter").toggleClass("active");
  };

  const categories = [...new Set(blogData.map((item) => item.category))];

  const filterByCategory = (category) => {
    let categ = blogData.filter((item) => item.category === category);
    setBlogData(categ);
    setCurrentPage(1); // Reset the current page to 1 when filtering by category
  };

  const filetrall = () => {
    fetch("https://apidata.foodbaskit.com/latest_blogs")
      .then((res) => res.json())
      .then((result) => {
        setBlogData(result);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <div>
      <div className="container">
        <div className="blogs_page">
          <div className="heading_main mt-5 mb-3">
            <h1>
              blogs page
            </h1>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="divde_by_cat">
                <div className="slect_picker">
                  <div className="filter_dropdown">
                    <button className='btn btn_filter' onClick={addclass}>
                      filter food by category
                    </button>
                    <div className="menu_filter active">
                      <ul>
                      <li>
                        <button onClick={filetrall}>
                          All
                        </button>
                      </li>
                        {categories.map((category, index) => (
                          <li key={index}>
                            <button  onClick={() => filterByCategory(category)}>{category}</button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="search_bar">
                  <div className="input-group mb-3">
                    <input type="text" className="form-control" placeholder="Search Your Food" aria-label="Recipient's username" aria-describedby="button-addon2"   value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}/>
                    <button className="btn btn_secondary" type="button" id="button-addon2">Button</button>
                  </div>
                </div>
              </div>
            </div>

            {currentItems.map((item) => {
              return (
                <div className="col-md-4" key={item.id}>
                  <div className="blogs_card">
                    <div className="card_wrap">
                      <div className="card_body">
                        <div className="blogs_latest">
                          <div className="card_img">
                            <img src={`https://apidata.foodbaskit.com/${item.image}`} className='img-fluid' alt="blogs" />
                          </div>

                          <div className="card_details">
                            <h2>
                              {item.name}
                            </h2>
                            <NavLink to={`/blog_detail/${item.id}`} className="blog_detail"> 
                              details
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="pagination">
                      <ul>
                        {pageNumbers.map((pageNumber) => (
                          <li
                            key={pageNumber}
                            className={`page-item ${
                              currentPage === pageNumber ? 'active' : ''
                            }`}
                          >
                            <a
                              href="#"
                              onClick={() => paginate(pageNumber)}
                              className="page-link"
                            >
                              {pageNumber}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
