import React from "react";
import Slider from "react-slick";
import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
const PopularBlogs = () => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const [blogData, setBlogData] = useState([]);

  useEffect(() => {
    fetch("https://apidata.foodbaskit.com/latest_blogs")
      .then((res) => res.json())
      .then((result) => {
        let filterCat = "popular";
        let cat = result.filter((item) => item.type === filterCat);
        setBlogData(cat);
    
      });
  }, []);

  return (
    <div>
    <div className="blogs_details_latest">
        <Slider {...settings}>
          {blogData.map((item) => {
            return (
              <div key={item.id}>
                <div className="card_wrap">
                  <div className="card_body">
                    <div className="blogs_latest">
                      <div className="card_img">
                      <img src={`https://apidata.foodbaskit.com/${item.image}`} alt="blogs" className='img-fluid'/>
                      </div>
                      <div className="card_details">
                        <h2>{item.name}</h2>
                        <NavLink to={`/blog_detail/${item.id}`} className="blog_detail">
  details
</NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default PopularBlogs;
