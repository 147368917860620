import React from "react";
import Slider from "react-slick";

const SimpleSlide = () => {
     const settings = {
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
        }
  return (
    <div>
    <Slider {...settings}>
    <div>
   <div className="imgs">
   <img src={require("../Images/slider1.jpg")} alt="img" className="img-fluid w-100" />
   </div>
    </div>
    <div>
    <div className="imgs">
    <img src={require("../Images/slider2.jpg")} alt="img" className="img-fluid w-100" />
    </div>
    </div>
    <div>
    <div className="imgs">
    <img src={require("../Images/slider3.jpg")} alt="img" className="img-fluid w-100" />
    </div>
    </div>
 
  </Slider>
    </div>
  )
}

export default SimpleSlide;
