import React, { useState } from 'react'
import $ from "jquery";
import { NavLink } from 'react-router-dom';
import Hamburger from 'hamburger-react'
const Header = () => {
  const [isOpen, setOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);

  function handleClick() {
    setIsActive(!isActive);
 
  }

  $(window).scroll(function(){
    let scrollbody=$(this).scrollTop();
    if(scrollbody >100){
  $("#auth_header").addClass("scroll");
    }
    else{
      $("#auth_header").addClass("scroll");
    }
   });

  return (
    <div>
  <header className={isActive ? 'active scroll ' : 'scroll'}  id="auth_header">
  <nav className="navbar navbar-expand-lg">
  <div className="container">
    <NavLink className="navbar_brand" to="/">
    <img src={require("../Images/logo.png")} className="img-fluid brand_logo" alt="logo" /></NavLink>
   
    <div className="navbar_nav desktop_version" id="desktop_version">
    <div className="menu_icon" id="toggle_menu" onClick={handleClick}>
    <Hamburger toggled={isOpen} toggle={setOpen} />
    </div>
  <div className="hide_dek">
  <ul className="navbar-nav ml-auto">
  <li className="nav-item">
      <NavLink className="nav-link" to="/">home</NavLink>
    </li>

    <li className="nav-item">
    <NavLink to="/blog" className="nav-link" >blog</NavLink>
  </li>
    <li className="nav-item">
    <NavLink to="/about" className="nav-link">about</NavLink>
  </li>

    <li className="nav-item">
      <NavLink to="/contact" className="nav-link" >contact</NavLink>
    </li>
 
  </ul></div>
    
    </div>
  </div>
</nav>

<div  className="mobile_versions" id="mobile_versions">
<div className="" id="mobile_bottom">
<div className="close_icon"  onClick={handleClick}>
<i className="fa-solid fa-xmark"></i>
</div>
<ul className="nav_mobile ">
<li className="nav-item">
    <NavLink className="nav-link" to="/">home</NavLink>
  </li>

  <li className="nav-item">
  <NavLink to="/blog" className="nav-link" >blog</NavLink>
</li>
  <li className="nav-item">
  <NavLink to="/about" className="nav-link">about</NavLink>
</li>

  <li className="nav-item">
    <NavLink to="/contact" className="nav-link" >contact</NavLink>
  </li>

</ul>
</div>

</div>
  
  </header>
    </div>
  )
}

export default Header;
