import React from 'react'
import $ from "jquery";
const Custom = () => {
 
   $(window).on("scroll",function(){
    let scrollbody=$(this).scrollTop();
    if(scrollbody > 150){
      $("#scroll_down").addClass("active");
   
    }
    else{
      $("#scroll_down").removeClass("active");
    }
    if(scrollbody >100){
    
  $("#auth_header").addClass("fixed")
    }
    else{
      $("#auth_header").removeClass("fixed")
    }
   })
   $(".btn_accordian").click(function () {
    if($(".btn_accordian[aria-expanded='true']")){
      $(this).parent().toggleClass("active");
    }
    else{
      $(this).parent().removeClass("active");
    }
      
    
        });



        $(".accordion-button").on("click",function(){
          $(this).addClass("active")
        });



        

  return (
    <div>
      
    </div>
  )
}

export default Custom;
