import React, { useState, useEffect } from 'react';
import "../src/components/css/style.css";
import "../src/components/css/responsive.css";
import "../src/components/css/video-react.css";
import 'bootstrap/dist/css/bootstrap.min.css';

import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Home from './components/Routing/Home';
import About from './components/Routing/About';
import Contact from './components/Routing/Contact';
import Custom from './components/Custom';
import Page404 from './components/Routing/Page404';
import Terms from './components/Routing/Terms';
import Blog from './components/Routing/Blog';
import 'react-toastify/dist/ReactToastify.css';
import BlogDetail from './components/Routing/BlogDetail';
import HashLoader from 'react-spinners/HashLoader';
import Scroll from './components/Routing/Scroll';
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
      toast.success("Welcome To FoodBaskit")
    }, 4000); // Simulate loading for 3 seconds
  }, []);

  return (
    <div>
      <BrowserRouter>
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <HashLoader color='#e5980f' />
          </div>
        ) : (
          <>
            <Header/>
            <ToastContainer/>
            <Routes>
              <Route path='/' element={<Home/>}/>
              <Route path='about' element={<About/>}/>
              <Route path='contact' element={<Contact/>}/>
              <Route path='terms' element={<Terms/>}/>
              <Route path='blog' element={<Blog/>}/>
              <Route path='/blog_detail/:id' element={<BlogDetail />} />
              <Route path="/:param1/*" element={<Page404/>}/>
            </Routes>
            <Custom/>
            <Scroll/>
            <Footer/>
          </>
        )}
     
      </BrowserRouter>
    </div>
  );
}

export default App;
