import React from 'react'
import SimpleSlide from '../Slider/SimpleSlide';
import LatestBlogs from '../Slider/LatestBlogs';
import Videoslider from '../Slider/Videoslider';
import Step from '../Slider/Step';
import Popularblogs from '../Slider/Popularblogs';
import { NavLink } from 'react-router-dom';
const Home = () => {

  fetch("https://apidata.foodbaskit.com/contact").then((res)=>{
    return res.json()
  }).then(()=>{
   
  })

  return (
    <div>
      <section>
        <div className="indexs_page">
          <div className="main_pages_slider">
            <SimpleSlide />
          </div>

          <div className="container">
            <div className="lates_blogs">

              <div className="heading_main">
                <h1>
                  Latest Blog Posts
                </h1>
              </div>

              <LatestBlogs />

            </div>


            <div className="popular_blogs">
              <div className="lates_blogs">

                <div className="heading_main">
                  <h1>
                    popular Blog Posts
                  </h1>
                </div>

                <Popularblogs />

              </div>
            </div>


            <div className="category_blogs">
              <div className="lates_blogs_category">

                <div className="heading_main">
                  <h1>
                    category of blogs
                  </h1>
                </div>

                <div className="filters">
                  <div className="filter_btn_wrap" id="categoryFIlterWraper">
                   
                    
                    
                   
                    <NavLink to="blog"  
                      className="  btn_filter"

                    >
                      <img src={require("../Images/nature.jpg")} alt="img" className='img-fluid' />
                      <span className="filter_txt">Asian Food </span>
                    </NavLink>
                    <NavLink to="blog"
                      className="  btn_filter"

                    >
                      <img src={require("../Images/travel.jpg")} alt="img" className='img-fluid' />
                      <span className="filter_txt">Bakery Stuff  </span>
                    </NavLink>
                    <NavLink to="blog"
                      className="  btn_filter"

                    >
                      <img src={require("../Images/food.avif")} alt="img" className='img-fluid' />
                      <span className="filter_txt">Western Food  </span>
                    </NavLink>
                    <NavLink to="blog"
                      className="  btn_filter"

                    >
                      <img src={require("../Images/food.avif")} alt="img" className='img-fluid' />
                      <span className="filter_txt">Street Food  </span>
                    </NavLink>
                    <NavLink to="blog"
                      className="  btn_filter"

                    >
                      <img src={require("../Images/food.avif")} alt="img" className='img-fluid' />
                      <span className="filter_txt">Mixed Foods  </span>
                    </NavLink>
                 
                  </div>
                </div>


              </div>
            </div>


            <div className="video_blogs">
              <div className="lates_blogs_video">

                <div className="heading_main">
                  <h1>
                    popular Blog Video's
                  </h1>
                </div>

                <Videoslider />

              </div>
            </div>




            {/* <div className="info_aboutus">
              <div className="info_aboutus_section">

                <div className="heading_main">
                  <h1>
                  information about us
                  </h1>
                </div>
                <div class="accordion accordion-flush" id="accordionFlushExample">
             <div className="row">

              <div className="col-md-6">
            
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
        Accordion Item #1
      </button>
    </h2>
    <div id="flush-collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the first item's accordion body.</div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
        Accordion Item #2
      </button>
    </h2>
    <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div className="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the second item's accordion body. Let's imagine this being filled with some actual content.</div>
    </div>
  </div>
 

              </div>

              <div className="col-md-6">
              <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#three" aria-expanded="false" aria-controls="flush-collapseThree">
        Accordion Item #3
      </button>
    </h2>
    <div id="three" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div className="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</div>
    </div>
  </div>



  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#four" aria-expanded="false" aria-controls="flush-collapseThree">
        Accordion Item #4
      </button>
    </h2>
    <div id="four" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
      <div className="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</div>
    </div>
  </div>
                </div>
             </div>

              </div>
            </div>
         
          </div> */}


          <div className="next_plan">
              <div className="lates_blogs_video">

                <div className="heading_main">
                  <h1>
                 our next step
                  </h1>
                </div>

              <div className="slide_for_next_step">
                <Step/>
              </div>

              </div>
            </div>

</div>
        </div>
      </section>


    </div>
  )
}

export default Home;