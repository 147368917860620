import React from "react";
import Slider from "react-slick";
import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
const Step = () => {
     const settings = {
          dots: true,
          infinite: false,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
        }

        const [blogData, setBlogData] = useState([]);

        useEffect(() => {
          fetch("https://apidata.foodbaskit.com/latest_blogs")
            .then((res) => res.json())
            .then((result) => {
              let filterCat = "step";
              let cat = result.filter((item) => item.type === filterCat);
              setBlogData(cat);
           
            });
        }, []);
  return (
    <div>
    <Slider {...settings}>
   {
    blogData.map((item)=>{
      return(
        <>
        <div key={item.id}>
<NavLink to={`/blog_detail/${item.id}`}>
<div className="imgs">
<img src={`https://apidata.foodbaskit.com/${item.image}`} alt="blogs" className='img-fluid'/>
   <div className="texts">
{item.description}
   </div>
   </div>
</NavLink>
    </div>
        </>
      )
    })
   }

 
  </Slider>
    </div>
  )
}

export default Step;
