import React from 'react'
import { NavLink } from 'react-router-dom'
const Footer = () => {
     return (
          <div>
               <footer>
                    <div className="footer_wrap">
                         <div className="container">
                              <div className="row">
                                   <div className="col-md-4">
                                        <div className="panel_logo_footer">
                                             <NavLink href="/">
                                                  <span className='food'>food</span> <span className='bakit'>
                                                       baskit</span>
                                             </NavLink>
                                        </div>
                                        <div className="TEXT_CONETNT">
                                        Office no. 8,
8th floor, 
Civic Avenue Mall,
Behria Phase 4,
Rawalpindi, Pakistan.
                                         
                                        </div>
                                   </div>
                                   <div className="col-md-4">
                                        <div className="links_sites">
                                             <div className="row">

                                                  <div className="col-md-4">
                                                       <NavLink to="/">
                                                            home
                                                       </NavLink>
                                                  </div>


                                                  <div className="col-md-4">
                                                       <NavLink to="contact">
                                                            contact us
                                                       </NavLink>
                                                  </div>

                                                  <div className="col-md-4">
                                                       <NavLink to="about">
                                                            about us
                                                       </NavLink>
                                                  </div>

                                                  <div className="col-md-4">
                                                       <NavLink to="terms">
                                                            Terms
                                                       </NavLink>
                                                  </div>
                                                
                                                  <div className="col-md-4">
                                                       <NavLink to="blog">
                                                            Blog
                                                       </NavLink>
                                                  </div>




                                             </div>
                                        </div>
                                   </div>
                                   <div className="col-md-4">
                                        <div className="social_links">
                                             <div className="heading_social">
                                                  <h3>
                                                       follow us
                                                  </h3>
                                             </div>
                                             <ul>

                                                  <li>
                                                       <NavLink href='https://youtube.com/@m.asadsardar4713'>
                                                            <div className="icon">
                                                                 <i class="fa-brands fa-facebook-f"></i></div>
                                                       </NavLink>
                                                  </li>

                                                  <li>
                                                       <a href='https://instagram.com/_foodbaskit.com_?igshid=MGNiNDI5ZTU=' target='blank'>
                                                            <div className="icon">
                                                                 <i class="fa-brands fa-instagram"></i></div>
                                                       </a>
                                                  </li>

                                                  <li>
                                                       <a href='https://youtube.com/@m.asadsardar4713' target='blank'>
                                                            <div className="icon">
                                                                 <i class="fa-brands fa-youtube"></i></div>
                                                       </a>
                                                  </li>

                                                

                                             </ul>
                                        </div>

                                   </div>
                              </div>

                         </div>
                    </div>
               </footer>
               <footer className='secondary_footer'>
                    <div className="text-center">
                         <div className="copyright">
                              Copyright © 2023 foodbaskit.com - Quality over Quantity

                         </div>
                    </div>
               </footer>
          </div>
     )
}

export default Footer
