import React, {useRef } from 'react';
import Slider from "react-slick";
import { Player } from 'video-react';
import { useState, useEffect } from "react";
import { NavLink } from 'react-router-dom';
const Videoslider = () => {
 
     const videoRef = useRef(null);
   
 
   
     const settings = {
          dots: false,
          infinite: false,
          speed: 500,
          slidesToShow: 3,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        };

        const [blogData, setBlogData] = useState([]);

        useEffect(() => {
          fetch("https://apidata.foodbaskit.com/latest_blogs")
            .then((res) => res.json())
            .then((result) => {
              let filterCat = "video";
              let cat = result.filter((item) => item.type === filterCat);
              setBlogData(cat);
           
            });
        }, []);
  return (
    <div>
    <Slider {...settings}>

{
  blogData.map((item)=>{
    return(<>
      <div>
<div className="clips">
<div className="card_wrap">
  <div className="card_body">

 <NavLink to={`/blog_detail/${item.id}`}>

 <video width="100%" height="100%" controls>
  <source src={`https://apidata.foodbaskit.com/${item.image}`} type="video/mp4"/>


</video>
 {/* <Player height="200px" width="100%">
  <source height="200px" width="100%" ref={videoRef} src={`https://apidata.foodbaskit.com/${item.image}`} />
</Player> */}


</NavLink>
  </div>
</div>
</div>
 </div>
    </>)
  })
}
  </Slider>
    </div>
  );
};

export default Videoslider
