import React from 'react'
import "../css/about.css"
const About = () => {
  return (
    <div>
<div id="carouselExample" class="carousel">
  <div class="carousel-inner">
    
    
    <div className="aboutus_text">

    <div class="carousel-item active">
      <img src={require("../Images/Our Mission (2).jpg")} class="d-block w-100" alt="..."/>
    </div>

<div className="row">
  <div className="col-6">

  </div>
  <div className="col-6">
  <div className="text_main">
<h1>
  about the asad sardar
</h1>
<div className="text">

A day is best started on a full stomach.
I share my food experiments for more than 25 countries and six continents. 
I create contents related to fast food, traditional food, street food and bakery foods for food lovers.
I like to experiment with new recipes, become aware of new food trends, restaurants, and other creative ideas.
I enjoy food blogging because it’s entertaining, amusing, and it’s my passion. 
I have visited all over the world to search the best cooked food for you.
Writing articles related to food is my expertise.

</div>
    </div>
  </div>
</div>

    </div>
  
   
  </div>


</div>

<div className="container">
<section className="about-section">
        <div className="container">
        <div className="row">                
  <div className="content-column col-lg-6 col-md-12 col-sm-12 order-2">
    <div className="inner-column">
      <div className="sec-title">
        <span className="title">About Asad Sardar</span>
        <h2>Sharing My Passion for  Food</h2>
      </div>
      <div className="text">
      About foodbaskit

We are actively working and sharing food and food related products reviews, pictures and videos since 2017 on Google Maps with name of M.  Asad Sardar.

The main purpose to share the views was to help the people searching for food.

To continue serve the people around the world we have created this website on it we will upload the popular and latest updates for food lovers. For updates keep following our pages.
      </div>
     
      <div className="btn-box">
        <a href="/contact" className="theme-btn btn-style-one">Contact Asad</a>
      </div>
    </div>
  </div>
  {/* Image Column */}
  <div className="image-column col-lg-6 col-md-12 col-sm-12">
    <div className="inner-column wow fadeInLeft">
      <div className="author-desc">
        <h2>ASAD SARDAR</h2>
        <span className='text-capitalize personality'>FOOD BLOGGER</span>
      </div>
      <figure className="image-1"><a href="/" className="lightbox-image" data-fancybox="images"><img title="Asad Sardar" src={require("../Images/slider_home.jpg")} alt="" /></a></figure>
    </div>
  </div>
</div>
          <div className="sec-title">
            <span className="title">About Foodbaskit</span>
            <h2> As a food blogger named Asad Sardar, I would like to introduce you to Foodbaskit</h2>
          </div>
          <div className="text">
          As a food blogger named Asad Sardar, I would like to introduce you to Foodbaskit - a creative online seller enthusiast that has been working since 2015. We are passionate about food and love to share our knowledge and experiences with our readers.
          </div>
     
          <div className="text">                
          Our aim is to lead in innovation and technology in the food industry. We work on UI/UX and functionality to create plugins that come with proper structure and stunning looks, which suit your web app and website. We take a small toolkit here and ride it well so that it is fit for your use. One that performs well and looks even better.
          </div>
          <div className="text">
          We create all kinds of technical content related to food, whether it is related to designing or functionality. We are creating content on a lot of languages and will continue to make it free of cost even if you use it without any problem. Our technical team reviews and shares content that our readers create on our blog.
          </div>
          <div className="text">
          Here at Foodbaskit, we believe in providing quality content that helps our readers learn more about food and cooking. Whether it's a recipe, food history, or food photography, we have got it all covered. We encourage our readers to share their experiences and recipes with us, and we are always happy to feature them on our blog.
          </div>
          <div className="text">
          In conclusion, we welcome you to our food blogging world and hope you enjoy the quality content that we create. Stay tuned for more exciting food-related updates!
          </div>
        </div>
      </section>
</div>

    </div>
  )
}

export default About