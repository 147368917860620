import React, { useState } from 'react';
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
const Contact = () => {
const navigate=useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        contact: '',
        message: '',
      });
    
      const handleInputChange = (event) => {
        setFormData({
          ...formData,
          [event.target.name]: event.target.value,
        });
      };
    
      const handleSubmit = (event) => {
        event.preventDefault();
        fetch('https://apidata.foodbaskit.com/contact', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body: JSON.stringify(formData),
        })
          .then((response) => {
            if(response.ok){
toast.success(" Form Submit Successfully ")
            }
            response.json()
          })
          .then((data) => {
         
            toast.success(`Thanks For Contact Us`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
      setTimeout(()=>{
        navigate("/")
      },3000)
          })
          .catch((error) => {
            console.error('Error inserting data into database:', error);
            // Handle the error
          });
      };
      
    
  return (
    <div>

<div className="container">
<section class="contact_uspage">

<section class="contact_us">
     <div class="container">
         <div class="row">
             <div class="col-md-10 offset-md-1">
                 <div class="contact_inner">
                     <div class="row">
                         <div class="col-md-10">
                             <div class="contact_form_inner">
                                 <div class="contact_field">
                                     <h3>contact Us</h3>
                                     <p>Feel Free to contact us any time. We will get back to you as soon as we can!.</p>
                                     <form onSubmit={handleSubmit}>
      <input type="text" placeholder="Name" className='form-control form-group' name="name" value={formData.name} onChange={handleInputChange} required/>
      <input type="text" placeholder="Email"  required className='form-control form-group'  name="email" value={formData.email} onChange={handleInputChange} />
      <input type="text" placeholder="Contact No" className='form-control form-group'  name="contact" value={formData.contact} onChange={handleInputChange} required />
      <textarea placeholder="Message" name="message" className='form-control form-group'  value={formData.message} onChange={handleInputChange}  required></textarea>
      <button type="submit" className='contact_form_submit'>Send</button>
    </form>
                                 </div>
                             </div>
                         </div>
                         <div class="col-md-2">
                             <div class="right_conatct_social_icon d-flex align-items-end">
                                <div class="socil_item_inner d-flex">
                                   <li><a href="https://instagram.com/_foodbaskit.com_?igshid=MGNiNDI5ZTU="><i class="fab fa-facebook-square"></i></a></li>
                                   <li><a href="https://instagram.com/_foodbaskit.com_?igshid=MGNiNDI5ZTU="><i class="fab fa-instagram"></i></a></li>
                                   <li><a href="https://instagram.com/_foodbaskit.com_?igshid=MGNiNDI5ZTU="><i class="fab fa-twitter"></i></a></li>
                                </div>
                             </div>
                         </div>
                     </div>
                     <div class="contact_info_sec">
                         <h4>Contact Info</h4>
                         <div class="d-flex info_single align-items-center">
                             <i class="fas fa-headset"></i>
                             <span>Cell <a href="/">+92 321 5000 258</a></span>
                         </div>
                         <div class="d-flex info_single align-items-center">
                             <i class="fas fa-envelope-open-text"></i>
                             <span>asadsardar@foodbaskit.com</span>
                         </div>
                         <div class="d-flex info_single align-items-center">
                             <i class="fas fa-map-marked-alt"></i>
                             <span>
                             Office no. 8,
8th floor, 
Civic Avenue Mall,
Behria Phase 4,
Rawalpindi, Pakistan.
                             </span>
                         </div>
         
                     </div>
                 </div>
             </div>
         </div>
     </div>
 </section>
 
 <section class="map_sec">
     <div class="container">
         <div class="row">
             <div class="col-md-10 offset-md-1">
                 <div class="map_inner">
                     <h4>Find Us on Google Map</h4>
                     <p>Address, Office 806, 8the Floor, Civic Mall, Behria Phase 4, Rawalpindi Cell</p>
                     <div class="map_bind">
                     <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d18133.286207836398!2d74.40568121321314!3d31.551857106960867!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39190feca0892e15%3A0xd9bdd4fa43e49a18!2sAli%20Hajveri%20Block%20Aziz%20Bhatti%20Town%2C%20Lahore%2C%20Punjab%2C%20Pakistan!5e1!3m2!1sen!2s!4v1680624980966!5m2!1sen!2s" width="100%" height="450" title="location" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        
                     </div>
                 </div>
             </div>
         </div>
     </div>
 </section>
 <ToastContainer/>

</section>
</div>

    </div>
  )
}

export default Contact